.view_part_section {
  display: flex;
  height: 93vh;
  flex-direction: column;
  align-items: center;
  background-color: #f0f2f0;
}
.mid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mid {
  height: 93vh;
  background-color: white;
}
.dropdown {
  margin-left: 1rem;
}
.select {
  width: 60px;
}
.card {
  margin-left: 1rem;
  border: 0px;
}
.card p {
  margin-top: 0.6rem;
}
.view_part {
  margin-top: -5rem;
  margin-right: 0rem;
  margin-left: 3.1rem;
  display: flex;
  justify-content: start;
  width: 560px;
  align-items: center;
}
.brief {
  width: 500px;
  margin-top: 3rem;
  margin-left: 1rem;
  font-size: 24px;
}
.view_link {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;
  padding: 60px;
  height: 82vh;
  overflow-y: scroll;
  justify-items: center;
}
.link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 150px;
  height: 150px;
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.733);
  border-radius: 40px;
  background: #fafafa;
  box-shadow: 20px 20px 60px #cccecc, -20px -20px 60px #ffffff;
}
.link:hover {
  background-color: #0033332a;
  color: rgba(0, 0, 0, 0.733);

  transition: background-color 0.4s ease-in-out;
}
.view_part_section .MuiFormControl-root {
  min-width: 400px !important;
}
.view_part_section .MuiSelect-select {
  min-width: 400px !important;
}
.MuiInput-underline:after {
  border-bottom: 2px solid #006974 !important;
}

.centerflex {
  display: flex;
  justify-content: center;
}
