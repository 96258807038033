/*@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");*/

.div-flex {
	display: flex;
	justify-content: space-between;
	margin: 0.8rem;
	margin-top: 20px;
	padding: 0.4rem;
}

.MuiPaper-elevation1 {
	box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%),
		0px 0px 0px 0px rgb(0 0 0 / 4%) !important;
}

.div-flex .MuiButton-root:hover {
	background-color: white !important;
}

.btn1 {
	border-width: 0px;
	
	width: 48;
}
/* 
.table {
	width: 100%;
	font-family: "Roboto", sans-serif;
	border: 1px solid black;
}

.card_table {
	margin: 0.8rem;
	padding: 0.8rem 0.5rem;
	box-shadow: 2px 2px 7px 1px rgba(0, 0, 0, 0.67);
}

.thead {
	border: 1px solid black;
}

.R1 > td {
	border: 1px solid black;
	padding: 5px;
	font-size: 14px;
	font-weight: 600;
}

.R2 > td {
	border-top: none;
	border: 1px solid black;
	font-size: 14px;
	padding: 0.5rem;
	font-weight: 600;
}

.R4 > td {
	border: 1px solid black;
	font-size: 13px;
	padding: 0.5rem;
}

/* Table 2 */
/* 
.tr1 > td {
	border: 1px solid black;
	font-size: 13px;
	padding: 0.5rem;
	font-weight: 700;
}

.table2_head {
	border: 1px solid black;
}
.tr2 > td {
	font-size: 13px;
	padding: 0.5rem;
	border: 1px solid black;
	font-weight: 700;
}
.tr1 > .td2 {
	width: 50px;
}
.tr1 > .td1 {
	width: 100px;
}

.tr1 > .td3 {
	width: 90px;
}
.tr1 > .td5 {
	width: 90px;
}

.tr1 > .td6 {
	width: 80px;
}

.tr1 > .td3 {
	width: 120px;
}

.tr2 > .td1,
.tr2 {
	width: 100px;
}

.tr2 > .td3,
.td4 {
	width: 90px;
}

.tr3 > td {
	border: 1px solid black;
	font-size: 13px;
	padding: 0.5rem;
	font-weight: 600;
}

.tr4 > td {
	border: 1px solid black;
	font-size: 13px;
	padding: 0.5rem;
	font-weight: 600;
}

.tr5 > td {
	border: 1px solid black;
	font-size: 13px;
	padding: 0.5rem;
}

.thead span {
	font-weight: 600;
} */ 
