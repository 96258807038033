.main.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 3rem;
  font-size: 62.5%;
  font-family: "roboto";
  background-color: #fafafa;
}

.container div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container div p {
  font-weight: 600;
}
