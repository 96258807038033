.divflex {
  display: flex;
  justify-content: space-between;
  /* margin: 0.8rem; */
  margin-bottom: 0px;
  /* margin-top: 20px; */
  padding: 0.75rem 1rem;
  background-color: #161a1d;
  color: white;
  border-top: 1px solid #494949;
  border-bottom: 1px solid #494949;
  font-family: "Nunito";
  letter-spacing: "0.2em";
}

.btnshadow {
  border-radius: 30px;
  width: 150px;
  height: 150px;
  background: #fafafa;
  box-shadow: 15px 15px 31px #cccecc, -15px -15px 31px #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnshadow:hover {
  background-color: #0342421f;
  /* width: -webkit-fill-available; */
  transition: background-color 0.4s ease-in-out;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 8px;
}

.header .process_name .select {
  min-width: 750px !important;
}

.select {
  padding: 0.5rem;
  margin-left: 1rem;
}
.header .process_name .MuiSelect-select {
  min-width: 720px !important;
}

.text p {
  margin: 0.8rem;
  font-size: 14px;
  margin-right: 1rem;
}

.grid1 {
  display: grid;
  margin-top: 4rem;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  border-bottom: 1px solid #c80202;
  margin-bottom: 1rem;
}

.grid1 p {
  margin-right: 6rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.6px;
}

.grid2 {
  margin-top: 0.25rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-left: 0.8rem;
}
.grid3 {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
}
