.MuiPickersToolbar-toolbar {
	background-color: teal !important;
}

.MuiPickersDay-daySelected {
	background-color: teal !important;
}

.MuiButton-textPrimary {
	background-color: teal !important;
	color: white !important;
}

/* .MuiSelect-select {
	width: 100% !important;
} */
.MuiOutlinedInput-root {
	width: 100%;
	text-align: start !important;
}
.select1 {
	width: 150px;
	padding: 10px;
	height: 43px;
	border: 0.5px solid black;
}

.cancel_operator {
	padding: 0.2rem;
	background-color: teal;
	color: white;
	border: none;
	width: 4rem;
	margin-top: 0.3rem;
}
