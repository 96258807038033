.task1 {
	display: flex;
	justify-content: space-evenly;
	/* flex-direction: column; */
	width: 100%;
	margin-top: -3rem;
}

.car {
	padding: 0px;
	border: 0px;
	background-color: #f0f2f0;
}

.part_name {
	width: 60%;
	margin-top: -2rem !important;
}
.part_name .MuiInputBase-input {
	width: 100% !important;
}

.lin {
	/* padding-left: 0px;
	font-size: 20px;
	background-color: #f0f2f0;
	padding: 0.5rem;
	width: fit-content;
	text-decoration: none !important;
	color: rgba(0, 0, 0, 0.733); */
	margin: 1rem 2.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	/* background-color: #94949454; */
	color: rgba(0, 0, 0, 0.733) !important;
	/* width: fit-content; */
	text-decoration: none !important;
	/* font-size: 20px; */

	width: 120px;
	height: 120px;
	border-radius: 40px;
	background: #f0f2f0;
	box-shadow: 20px 20px 60px #cccecc, -20px -20px 60px #ffffff;
}
.lin:hover {
	color: rgba(0, 0, 0, 0.733);
	background-color: #0342421f;
	/* width: -webkit-fill-available; */
	transition: background-color 0.4s ease-in-out;
}
