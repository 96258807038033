.home {
  display: flex;
  height: 90vh;
  justify-content: space-between;
}
.image {
  display: flex;
  flex: 0.4;
  align-items: center;
  justify-items: center;
  background-color: white;
}

.mushin_logo {
  width: 300px;
  height: 300px;
}
.adminSection {
  display: flex;
  flex: 0.6;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #fafafa;
}

.user_details {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: -5rem;
  /* position: absolute;
	top: 7rem; */
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: thin;
  color: black;
}

.avatar {
  /* position: relative;
	left: 80px;
	top: -20px; */
}

.task {
  display: flex;
  justify-content: space-evenly;
  /* flex-direction: column; */
  width: 100%;
  height: 20%;
  margin-top: 4.5rem;
  margin-bottom: 4rem;
}

.panel {
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #94949454; */
  color: rgba(0, 0, 0, 0.733) !important;
  text-decoration: none !important;
  margin-top: 1rem;
  width: 150px;
  height: 150px;
  border-radius: 40px;
  background: #fafafa;
  box-shadow: 20px 20px 60px #cccecc, -20px -20px 60px #ffffff;
}

.panel:hover {
  background-color: #0342421f;

  transition: background-color 0.4s ease-in-out;
}

.card-btn-para {
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  font-weight: 500;
}

.card-btn-span {
  display: block;
  font-weight: 400;
}
