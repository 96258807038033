.cancel_in_pir {
  height: 2rem;
  width: 2rem;
  position: absolute;
  top: 0.2rem;
  right: 0rem;
}
.add_button_container {
  width: fit-content;
  flex-basis: 20%;
  background-color: #fafafa;
  border: 1px solid #f0f2f0;
}

.checktime-input {
  flex-basis: 20%;
}

.add_button_container p {
  font-size: 12px;
  padding: 0rem 0.6rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0rem;
  text-align: center;
  flex: 1;
}

.pir-process-detail {
  flex-basis: 16.2%;
}

.pir_add_modal {
  width: 45px;
  height: 45px;
  background-color: #c80202;
  color: white;
  cursor: pointer;
}

.pir-modal-container .MuiTextField-root {
  margin: 0 !important;
  padding: 4px !important;
}

.pir-select .MuiInputBase-input:focus {
  background-color: #f5f5f5 !important;
}
