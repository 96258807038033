#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border-bottom: 1px solid #ddd;
  padding: 14px;
  padding-left: 0px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: rgb(255, 255, 255);
}
#customers td {
  text-align: center;
}
#customers th {
  padding-top: 20px;
  padding-bottom: 12px;
  text-align: center;

  color: rgb(10, 10, 10);
}
.createButton {
  background-color: rgb(131, 2, 2);
  color: aliceblue;
  padding: 6px;
  margin: 0px;
  border-radius: 4px;
  font-weight: lighter;
  font-size: 18px;
  border: 0px;
}
.createButton:hover {
  background-color: rgb(204, 1, 1);
}

.button1 {
  background-color: rgb(3, 116, 63);
  color: aliceblue;
  padding: 4px;
  margin: 0px;
  border-radius: 8px;
  font-weight: lighter;
  font-size: 18px;
  border: 0px;
}
.cancel {
  background-color: rgb(131, 2, 2);
  color: aliceblue;
  padding-right: 14px;
  padding-left: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: -15px;
  margin-bottom: 0px;
  border-radius: 3px;
  font-weight: lighter;
  font-size: 18px;
  border: 0px;
}

.create-user-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
