@import url("https://fonts.googleapis.com/css2?family=Varela&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Roboto", "Varela", sans-serif;
    position: relative;
    overscroll-behavior-x: none;
    /* background-color: #f0f2f0; */
}

section[disabled] {
    pointer-events: none;
    opacity: 0.4;
    background: #f5f5f5;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
