.login-container {
  height: 100vh;
  display: flex;
  align-items: center;
  background-color: white;
}
.login {
  padding: 0px 34px;
  background-color: #1d1d1d;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.login form {
  background-color: #fafafa;
  padding: 50px;
}

.login-container-heading {
  margin-bottom: 2rem;
  font-size: 44px;
  font-family: "Nunito", sans-serif;
  color: #fafafa;
}

@media all and (max-width: 800px) {
  .login-container {
    flex-direction: column;
  }
}
@media all and (max-width: 450px) {
  .login form {
    padding: 22px;
  }
  .login-container-heading {
    font-size: 30px;
  }
}
