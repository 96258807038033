.subheading-container {
  background-color: #fafafa;
  padding: 1rem;
}

.subheading-primary {
  color: #c80202;
  font-size: 1.4rem;
  letter-spacing: 1.6px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 500;
}

.subheading-secondary {
  font-weight: 400;
  color: black;
}
