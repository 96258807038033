.download-table-xls-button {
	border-width: 0 !important;
}

.spc {
	margin-left: 1rem;
	margin-top: 3rem;
	margin-bottom: 2rem;

	/* display: flex;
    justify-content: space-evenly; */
}
.des {
	display: flex;
	justify-content: space-evenly;
}
.des1 {
	/* margin-bottom: 2rem; */
	margin-right: 2rem;
	display: flex;
	font-size: 14px;
}

/* .des2 {
	margin-bottom: 2rem;
	display: flex;
	font-size: 14px;
} */

.spc .title {
	font-size: 24px;
}
.spc .des {
	margin-top: 3rem;
}
.pa {
	font-size: 16px;
	font-weight: 500;
	margin-right: 1rem;
	margin-bottom: 0;
}
