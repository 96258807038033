.main_container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 10px;
}

.main_container .firstContainer {
  display: grid;
  grid-template-columns: repeat(2, minmax(10rem, 1fr));
  column-gap: 10px;
}

.main_container .firstContainer > * {
  width: 1fr !important;
}

.main_container .firstContainer .formControl {
  width: 0.8fr !important;
}

.main_container .secondContainer {
  display: grid;
  grid-template-columns: 1fr;
}
