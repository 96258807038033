.specs-field {
  margin-left: 0px !important;
  margin-left: 1rem;
  background-color: white;
  padding-top: 1rem;
}

.specs-field .MuiOutlinedInput-root {
  width: 100% !important;
  /* border-radius: 0px !important; */
}

.failure-field {
  margin-left: 0px !important;
  margin-left: 1em;
  padding-bottom: 0.5rem;
  background-color: white;
}

.failure-field-section {
  display: flex;
}

.failure-field-section > div {
  flex-basis: 25% !important;
}

.failure-field .MuiTextField-root {
  width: 100% !important;
}
.failure-container {
  margin-top: 1.5rem;
  width: 100%;
  margin-left: 0.6rem;
}
.specs-container {
  margin-top: 1.5rem;
  margin-left: 0.6rem;
}

.MuiButton-contained {
  box-shadow: none;
}
.btn_div_flex {
  position: absolute;
  top: -0.2rem;
  right: 5rem;
}

.btn_div_flex > Button {
  margin-left: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 0.8rem;
}

.flexcenter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}

.remove {
  margin-top: 0.8rem;
  margin-bottom: 0.4rem;
  margin-right: 1rem;
  grid-column: 6/-1;
  justify-self: end;
}

.MuiOutlinedInput-input {
  padding: 12px !important;
}

.MuiFormLabel-root {
  font-size: 0.9rem !important;
}

.add_specs .MuiButton-label {
  justify-content: start;
}
.add_failures .MuiButton-label {
  justify-content: start;
}

.add_process {
  margin-top: 1.5rem !important;

  color: black !important;
}

.MuiOutlinedInput-multiline {
  padding: 0px;
  height: 40px;
}
.MuiOutlinedInput-inputMultiline {
  padding: 2px !important;
}

/* 4m Section  */
.m-section .MuiOutlinedInput-root {
  width: 100% !important;
  /* border-radius: 0px !important;
	 */
}

.m-field {
  margin-top: 1.5rem;
  margin-left: 1rem;
  padding: 0.5rem 0.5rem;
}

/* 4m Section  */
.m-section {
  margin-left: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.m-field {
  background-color: white;

  margin-top: 1.5rem;
  margin-left: 0px;
  /* border-radius: 0.5rem; */
  padding: 0.5rem 0.5rem;
}

.makeStyles {
  width: 100% !important;
}
.m-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  width: 100%;
  background-color: white;
  margin-top: 18px;
  margin-left: 1rem;
  padding: 0.8rem 0.3rem;
  padding-bottom: 12px;
  padding-top: 12px;
}

.m-section-main {
  width: 332px;
  margin-left: 1rem;
  margin-top: 0.8rem;
}

.m-section-main .MuiTextField-root {
  margin: 0 !important;
  padding: 0 !important;
  width: 275px;
}
.m-section-main .MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(5px, 1px) !important;
}
