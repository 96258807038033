.main-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.process-fmea-container {
  display: grid;
  grid-template-columns: repeat(2, 350px);
  flex: 1;
}

.process-fmea-container > div {
  flex-basis: 50% !important;
}

.process-fmea-container1 {
  display: grid;
  grid-template-columns: repeat(3, 350px);
  flex: 1;
}
.process-fmea-container1 > div {
  flex-basis: 30% !important;
}

.fmea-input {
  width: 100% !important;
}

.main-button-container {
  display: "flex";
  /* gap: "6rem"; */
  align-items: center;
}
